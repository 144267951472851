import useGlobal from 'hooks/global';
import { createReport, getUser, getBloodTestParameters, getAllParameters, validateBloodTest } from 'api';
import { Flex, Heading, Text } from 'rebass';
import { Plural, Trans } from '@lingui/macro';
import { FaExclamationTriangle } from 'react-icons/fa';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { useEffect, useMemo, useRef, useState } from 'react';
import Loader, { LoaderSmall } from 'components/common/Loader';
import ModelGroup from './components/ModelGroup';
import ModelRequirmentsNotMetModal from './components/ModelRequirementsNotMetModal';
import NoRemaininEventsModal from './components/NoRemaininEventsModal';
import { getSubscriptionBillingEvents, getSubscriptionStatusFromUser } from 'components/common/Dropdown';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export default function AvailableModels({ onCreateReport, match, disabled, testHasReports, skipFetchValues }) {
  const { showError } = useErrorDialog();
  const [globalState, globalActions] = useGlobal();
  const { bloodTest, user, patient } = globalState;

  const savedParamsMap = useMemo(() => {
    const m = new Map();

    globalState.savedParameters.forEach((sp) => {
      m.set(sp.code, true);
    });

    return m;
  }, [globalState.savedParameters]);

  const allParamsMap = useMemo(() => {
    const m = new Map();

    globalState.parameters.forEach((param) => {
      m.set(param.code, { name: param.name, units: getUnitsByConventionType(param.units) });
    });

    return m;
  }, [globalState.parameters]);

  const [activeIndex, setActiveIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const knownParamsFetchStatus = useRef('UNDEFINED');
  const savedParamsFetchStatus = useRef('UNDEFINED');
  const requirementFetchStatus = useRef('UNDEFINED');

  const [modelReqError, setModelReqError] = useState({
    isLarge: false,
    title: undefined,
    error: undefined,
    notFoundParams: undefined,
  });

  const [modelCreateConfirm, setModelCreateConfirm] = useState({
    message: undefined,
    onConfirm: undefined,
  });

  const [availableModelGroups, setAvailableModelGroups] = useState(undefined);

  useEffect(() => {
    const fetchMissingData = async () => {
      try {
        if (skipFetchValues && globalState.savedParameters && (globalState.savedParameters?.length || 0) > 0) {
          savedParamsFetchStatus.current = 'DONE';
        }

        if (savedParamsFetchStatus.current === 'UNDEFINED') {
          savedParamsFetchStatus.current = 'LOADING';
          const res = await getBloodTestParameters(match.params.id, match.params.bloodTestId);
          globalActions.setSavedParameters(res);
          savedParamsFetchStatus.current = 'DONE';
        }

        if (globalState.parameters && (globalState.parameters?.length || 0) > 0) {
          knownParamsFetchStatus.current = 'DONE';
        }

        if (knownParamsFetchStatus.current === 'UNDEFINED') {
          knownParamsFetchStatus.current = 'LOADING';
          const res = await getAllParameters();
          globalActions.setParameters(res);
          knownParamsFetchStatus.current = 'DONE';
        }

        if (requirementFetchStatus.current === 'UNDEFINED') {
          requirementFetchStatus.current = 'LOADING';

          const allModelGroups = await validateBloodTest(match.params.id, match.params.bloodTestId);
          setAvailableModelGroups(getAvailableModelGroups(allModelGroups, allParamsMap));

          requirementFetchStatus.current = 'DONE';
        }

        setIsLoading(
          knownParamsFetchStatus.current !== 'DONE' ||
            savedParamsFetchStatus.current !== 'DONE' ||
            requirementFetchStatus.current !== 'DONE',
        );
      } catch (e) {
        console.log(e);
        showError(e);
      }
    };

    fetchMissingData();
  }, [
    globalState.parameters,
    globalState.savedParameters,
    globalActions,
    globalActions.setSavedParameters,
    globalActions.setParameters,
    match.params.id,
    match.params.bloodTestId,
    skipFetchValues,
    showError,
    allParamsMap,
  ]);

  const validateBloodTestResult = async (onSuccesfulGetGroups, skipFetch) => {
    let newAvailableModelGroups = availableModelGroups;

    if (!skipFetch) {
      setIsLoading(true);
      const allModelGroups = await validateBloodTest(match.params.id, match.params.bloodTestId);
      newAvailableModelGroups = getAvailableModelGroups(allModelGroups, allParamsMap);
      setAvailableModelGroups(newAvailableModelGroups);
      setIsLoading(false);
    }

    if (onSuccesfulGetGroups) {
      setTimeout(() => onSuccesfulGetGroups(newAvailableModelGroups));
    }
  };

  const [createReportError, setCreateReportError] = useState(undefined);
  const [noBillingEventRemainingModalShown, setNoBillingEventRemainingModalShown] = useState(false);

  const generateReport = async (code) => {
    try {
      const newReport = await createReport(match.params.id, match.params.bloodTestId, code);
      globalActions.pushReport(newReport[0]);
      const refreshedUser = await getUser(); // need to retrieve the new report quota
      globalActions.setUser(refreshedUser);
      setActiveIndex(null);
      setTimeout(() => {
        onCreateReport?.();
      }, 0);

      if (testHasReports) {
        validateBloodTestResult();
      }
    } catch (e) {
      if (e.error === 'billing_events_exhausted') {
        setNoBillingEventRemainingModalShown(true);
      } else {
        if (e.values && Array.isArray(e.values)) {
          setCreateReportError(e.values.join(';\r\n'));
        } else {
          setCreateReportError(e.error || e.detail || 'Unexpected error!');
        }
      }
    } finally {
      setActiveIndex(null);
    }
  };

  const validateGroupModelIndex = async (groupProductName, modelName, modelIndex) => {
    function getErrorText(group, model) {
      if (group.isMissingParams) {
        return {
          title: <Trans>More blood parameters are required!</Trans>,
          message: (
            <Trans>{`The following blood parameters are missing but are required to generate a "${model.name}" report:`}</Trans>
          ),
        };
      }

      if (group.invalid_age) {
        return {
          title: <Trans>Invalid patient age</Trans>,
          message: <Trans>Patient must be at least {group.invalid_age} years old.</Trans>,
        };
      }

      if (group.insufficient_number_of_blood_parameters) {
        return {
          title: <Trans>More blood parameters are required!</Trans>,
          message: (
            <Plural
              value={group.insufficient_number_of_blood_parameters - savedParamsMap?.size}
              _1={`You need at least # more blood parameters to generate a "${model.name}" report.`}
              _2={`You need at least # more blood parameters to generate a "${model.name}" report.`}
              other={`You need at least # more blood parameters to generate a "${model.name}" report.`}
            />
          ),
        };
      }

      if (group.appNotAwareErrors.length > 0) {
        return {
          title: <Trans>Validation errors</Trans>,
          message: (
            <>
              <Trans>Unexpected validation errors occurred. Original error messages:</Trans>
              {group.appNotAwareErrors.map((e) => (
                <div key={e.code} style={{ marginTop: '0.5em' }}>
                  {e.detail}
                </div>
              ))}
            </>
          ),
        };
      }

      return {
        title: <Trans>Product not available</Trans>,
        message: <Trans>The product is not available at the moment. Please contact the app administrator.</Trans>,
      };
    }

    validateBloodTestResult((groups) => {
      const group = groups.find((g) => g.product_name === groupProductName);
      const model = group.models.find((m) => m.name === modelName);
      const isOnlyOneModelInGroup = group.models.length === 1;

      if (!group.is_available) {
        const { title, message } = getErrorText(group, model);

        setModelReqError({
          title,
          error: (
            <>
              {message}
              {group.isMissingParams && (
                <div
                  style={{
                    marginTop: '0.5em',
                    marginBottom: '0.5em',
                    maxHeight: '370px',
                    overflowY: 'auto',
                    height: '100%',
                  }}
                >
                  {group.notFoundParams.map((nfp, i) => (
                    <div
                      key={nfp.name}
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'bold',
                        borderBottom: '1px solid #f7f7f7',
                        background: i % 2 === 1 ? 'rgb(251, 251, 251)' : undefined,
                        padding: '0.7ch 0.4ch',
                      }}
                    >
                      {nfp.name}
                    </div>
                  ))}
                </div>
              )}
            </>
          ),
          isLarge: group.isMissingParams,
          notFoundParams: group.notFoundParams,
        });
      } else {
        if (group.requires_billing_event || group.research_use_only) {
          setModelCreateConfirm({
            message: (
              <>
                <Text>
                  <Trans>
                    Generate a report using the <b>{model.name}</b> model?
                  </Trans>
                </Text>
                {group.research_use_only && (
                  <Text sx={{ mt: 3 }} color="orange">
                    <Trans>For educational and research purposes only (not for clinical use)</Trans>
                  </Text>
                )}
                {group.requires_billing_event && (
                  <Text sx={{ mt: 3 }} color="orange">
                    {isOnlyOneModelInGroup ? (
                      <Trans>This action will cost you {1} billing event!</Trans>
                    ) : (
                      <Trans>
                        This action will cost you {1} billing event and cover all {group.product_name} models.
                      </Trans>
                    )}
                  </Text>
                )}
              </>
            ),
            onConfirm: () => {
              generateReport(model.code);
              setActiveIndex(`${group.product_name}_${modelIndex}`);
            },
          });
        } else {
          generateReport(model.code);
          setActiveIndex(`${group.product_name}_${modelIndex}`);
        }
      }
    }, testHasReports);
  };

  if (isLoading) {
    return testHasReports ? (
      <Loader big padding={4} />
    ) : (
      <Flex p={3}>
        <LoaderSmall dark />
      </Flex>
    );
  }

  return (
    <>
      <ConfirmationModal
        cancelText={<Trans>Close</Trans>}
        header={<Trans>Error!</Trans>}
        open={createReportError != null}
        handleClose={() => setCreateReportError(undefined)}
        message={createReportError}
      />

      <ConfirmationModal
        open={modelCreateConfirm.message != null}
        handleConfirm={modelCreateConfirm.onConfirm}
        message={modelCreateConfirm.message}
        handleClose={() => setModelCreateConfirm({ message: undefined, onConfirm: undefined })}
      />

      <NoRemaininEventsModal
        open={noBillingEventRemainingModalShown}
        handleClose={() => setNoBillingEventRemainingModalShown(false)}
      />

      <ModelRequirmentsNotMetModal
        open={modelReqError.error != null}
        title={modelReqError.title}
        message={modelReqError.error}
        isLarge={modelReqError.isLarge}
        notFoundParams={modelReqError.notFoundParams}
        bloodTestId={bloodTest.id}
        bloodTestStatus={bloodTest.status}
        patientId={patient.id}
        defaultConventionType={bloodTest.convention_type}
        onSuccessfulEmptyParamInset={() => {
          setModelReqError({ isLarge: false, error: undefined, notFoundParams: undefined, title: undefined });
          setIsLoading(true);

          const updateParams = async () => {
            try {
              const res = await getBloodTestParameters(match.params.id, match.params.bloodTestId);
              globalActions.setSavedParameters(res);
            } catch (error) {
              console.log(error);
              showError(error);
            }

            setIsLoading(false);
          };

          updateParams();
        }}
        handleClose={() =>
          setModelReqError({ isLarge: false, error: undefined, notFoundParams: undefined, title: undefined })
        }
      />

      <Flex flexDirection={'column'} alignItems={testHasReports ? undefined : 'end'}>
        {getSubscriptionBillingEvents(getSubscriptionStatusFromUser(user), 0) <= 0 ? (
          <Heading fontWeight={300} color="primary" py={testHasReports ? undefined : 2}>
            <Trans>You have reached your billing events quota!</Trans>
          </Heading>
        ) : (
          <>
            {availableModelGroups?.length > 0 && !disabled && (
              <Heading fontWeight={300} color="primary" p={2} pb={0}>
                <Trans>Select a model to generate the report</Trans>
              </Heading>
            )}
          </>
        )}

        {bloodTest.reports.length === 0 && bloodTest.is_on_treatment && (
          <Text fontSize={2} color="orange" mt={3}>
            <FaExclamationTriangle style={{ marginBottom: '-2px' }} />{' '}
            <Trans>Patient was on treatment at the time of test</Trans>
          </Text>
        )}
        {availableModelGroups?.length > 0 && (
          <Flex justifyContent={testHasReports ? 'center' : 'end'} width={1} flexDirection="row-reverse">
            {availableModelGroups.map((modelGroup) => {
              return (
                <ModelGroup
                  key={modelGroup.product_name}
                  group={modelGroup}
                  disabled={disabled}
                  activeIndex={activeIndex}
                  onModelClick={validateGroupModelIndex}
                />
              );
            })}
          </Flex>
        )}
      </Flex>
    </>
  );
}

export function getUnitsByConventionType(units) {
  const groupedData = {};

  units.forEach((unit) => {
    const conventionTypes = unit.convention_type;
    const name = unit.name;

    conventionTypes.forEach((conventionType) => {
      if (!groupedData[conventionType]) {
        groupedData[conventionType] = [];
      }

      groupedData[conventionType].push(name);
    });
  });

  return groupedData;
}

function getAvailableModelGroups(allModelGroups, allParamsMap) {
  const availableModelGroups = (allModelGroups || []).filter((group) => {
    const availModels = group.models.filter((model) => model.report_exists === false);

    if (availModels.length === 0) {
      return false;
    }

    group.models = availModels;

    group.invalid_age = group.errors?.find((e) => e.code === 'invalid_age')?.attr;
    group.insufficient_number_of_blood_parameters =
      group.errors?.find((e) => e.code === 'insufficient_number_of_blood_parameters')?.attr || 0;

    group.notFoundParams = (group.errors || [])
      .filter((e) => e.code === 'missing_required_blood_parameter')
      .map((e) => {
        const paramDef = allParamsMap?.get(e.attr);
        return {
          code: e.attr,
          name: paramDef?.name || e.attr,
          units: paramDef?.units || {},
        };
      });

    group.isMissingParams = group.notFoundParams.length > 0;
    group.appNotAwareErrors = group.errors?.filter(
      (e) =>
        !['invalid_age', 'missing_required_blood_parameter', 'insufficient_number_of_blood_parameters'].includes(
          e.code,
        ),
    );

    return true;
  });

  return availableModelGroups;
}
